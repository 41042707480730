// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Popup from '../../components/Layout/popup';

// Feature Images
import saas2 from '../../assets/images/Aquila Academy/founder1.jpg';
import saas3 from '../../assets/images/Aquila Academy/founder2.jpg';

import Section from './Home';
import { agencyFeatureArray } from '../../common/data';

const IndexAgency = () => {
  return (
    <React.Fragment>
      {/* Feature */}
      <section className="section">
        <h2 className="title text-center">About Founders</h2>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={1} md={1}></Col>
            <Col lg={4} md={4}>
              <img src={saas2} className="img-fluid " alt="" />
            </Col>
            <Col lg={1} md={1}></Col>

            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-3">
                <h4 className="title mb-4">Bindu Umashankar</h4>
                <h5 style={{ marginTop: '-20px' }}>Founder</h5>
                {/* <p className="text-muted" style={{ textAlign: 'justify' }}> </p> */}
                <ul className="list-unstyled text">
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Mrs. Bindu Umashankar, the visionary founder of Aquila
                    Academy, exemplifies dedication to holistic education. With
                    an impressive academic background, Bindu holds a degree in
                    Engineering in Biotechnology and a Master's in Microbiology,
                    where they earned a prestigious gold medal.
                  </li>

                  <li className="mb-1">
                    <span className="text-primary h5 me-2 text-align-justify">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Beyond academic achievements, Bindu's fervent commitment to
                    early childhood development sets them apart. Having
                    completed a specialized course in early childhood education,
                    Bindu understands the critical importance of nurturing young
                    minds during their formative years.
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2 text-align-justify">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    With three years of experience teaching college students,
                    Bindu brings a multifaceted approach to education,
                    integrating scientific rigor with a profound understanding
                    of children's developmental needs. This unique combination
                    ensures that Aquila Academy provides a nurturing and
                    stimulating environment for every student, fostering growth
                    and learning in all aspects of their development.
                  </li>
                </ul>
                {/* <Link to="#" className="mt-3 h6 text-primary">
                  Find Out More{' '}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link> */}
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-5">
            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-3">
                <h4 className="title mb-4">Mr. Bhaskar Gopal Reddy</h4>
                <h5 style={{ marginTop: '-20px' }}>Founder</h5>
                {/* <p className="text-muted" style={{ textAlign: 'justify' }}> </p> */}
                <ul className="list-unstyled text">
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Mr. Bhaskar Gopala Reddy, the esteemed co-founder of Aquila
                    Academy, brings a dynamic blend of experiences and expertise
                    to the institution. Formerly an engineer at Infosys, Mr.
                    Reddy's passions extend beyond the tech realm into the
                    cricket field, where he is not just a player but a dedicated
                    athlete.
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    With a Master's degree in Sports Management from University
                    of Stirling Scotland, Mr. Reddy has honed his skills both on
                    and off the pitch. He has proudly represented Clackmannan
                    Country Cricket Club and currently plays in the KSCA 3rd
                    Division.
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    However, it is Mr. Reddy's profound belief in early
                    childhood development that truly defines him. He champions
                    physical fitness and holistic growth for children, ensuring
                    that Aquila Academy embodies these principles at its core.
                    His commitment to fostering a nurturing environment for
                    young minds is integral to the academy's mission of
                    promoting comprehensive development and well-being for every
                    student.
                  </li>
                </ul>
                {/* <Link to="#" className="mt-3 h6 text-primary">
                  Find Out More{' '}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link> */}
              </div>
            </Col>
            <Col lg={1} md={1}></Col>
            <Col lg={4} md={4}>
              <img src={saas3} className="img-fluid " alt="" />
            </Col>
            <Col lg={1} md={1}></Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default IndexAgency;
