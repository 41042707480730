import React, { useEffect, useState } from 'react';
import Navbar from './NavbarPage';
import Home from './Home';
import Gallery from './Gallery';
import Cta from './Cta';
import Services from './Services';
import Testimonials from './Testimonials';
import Contact from './Contact';
import Curriculam from './Curriculam';
import AboutUs from './AboutUs';
import Classroom from './Classroom';
import HomeMobile from './HomeMobile';
import PopularDestination from './PopularDestination';

const Index = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    document.body.classList = '';
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = () => {
    const navBar = document.getElementById('topnav');
    const top =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
        document.getElementById('topnav').classList.add('bg-white');
      } else {
        navBar.classList.remove('nav-sticky');
        document.getElementById('topnav').classList.remove('bg-white');
      }
    }
  };

  return (
    <>
      <Navbar />
      {/* <div id="home">
        <Home />
      </div> */}

      {isMobileView ? (
        <div id="home">
          <PopularDestination />
        </div>
      ) : (
        <div id="home">
          <Home />
        </div>
      )}

      <div id="classroom">
        <Classroom />
      </div>
      <div id="curriculam">
        <Curriculam />
      </div>
      <div id="gallery">
        <Gallery />
      </div>
      <Cta />

      <Services />
      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M720 125L2160 0H2880V250H0V125H720Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div id="aboutus">
        <AboutUs />
      </div>
      <Testimonials />
      <div id="contact">
        <Contact />
      </div>
    </>
  );
};

export default Index;
