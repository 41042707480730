import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';

//Star Rating
import StarRatings from 'react-star-ratings';
import ProfilePic from '../../assets/images/Todzee/profileImage.jpg';

class ReviewSlider4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.reviews = [
      {
        id: 1,
        image: ProfilePic,
        name: 'P/O Ummehani Noori',
        rating: 5,
        desc: "I am delighted to share my beautiful experience with Aquila Academy Preschool. It is a place that has truly made a remarkable impact on my child's early education journey. From the moment we stepped through the doors of this nurturing preschool, we knew we had found the perfect place for our little one",
      },
      {
        id: 2,
        image: ProfilePic,
        name: 'P/O Maanvi P',
        rating: 5,
        desc: 'Maanvi joined Acquilla in July 2023 and the playgroup experience has been truly remarkable. Her teachers are super nice and make her feel happy and safe. She loves her teachers a lot. They teach her fun things and help her make new friends. At the playgroup, Maanvi does lots of fun activities like drawing, playing.',
      },
      {
        id: 3,
        image: ProfilePic,
        name: 'P/O Ishaan Rahul Naikare',
        rating: 5,
        desc: 'Ishaan joined Aquila in July 2023, being that it was his first time attending school, the teachers really assisted him in transitioning into a pre-school setting and made me also feel comfortable. My child has learned so much from the pre-school program. The minute he started at Aquila, we saw changes in his independence and confidence',
      },
      {
        id: 4,
        image: ProfilePic,
        name: 'P/O Ridhaan Aswin',
        rating: 5,
        desc: 'I would like to appreciate the beloved staffs of Aquila Academy for all the love, care, patience and dedication that you put into teaching our child. I have noticed countless changes in Ridhaan since he joined the preschool. His communication has improved and he have become independent in many day to day activities.',
      },
      {
        id: 5,
        image: ProfilePic,
        name: 'Nazeefa Kanchwala',
        rating: 5,
        desc: 'A very good playschool where the child gets wholesome care along with the education. My child fell in love with learning and is now super excited to go to school everyday. They have skills lab which is very unique and engaging for the Lil ones. Very happy with the teachers and the facilities there .',
      },
      {
        id: 6,
        image: ProfilePic,
        name: 'Varsha P',
        rating: 5,
        desc: "We're so happy we enrolled our child at Aquila Academy 10 months ago, when he was just 18 months old. Our little one has always been eager to interact, but as a nuclear family, we realized the limitations of daily social engagement. Aquila has truly been a transformative environment.",
      },
    ];
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.clients.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.clients.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    return (
      <React.Fragment>
        <Carousel
          activeIndex={this.state.activeIndex}
          next={this.next}
          previous={this.previous}
        >
          {this.reviews.map((client, key) => (
            <CarouselItem
              key={key + 1}
              onExiting={this.onExiting()}
              onExited={this.onExited()}
            >
              <div key={key} className="customer-testi text-center">
                <p className="text-light para-dark h6 fst-italic mb-2">
                  " {client.desc} "
                </p>
                <StarRatings
                  rating={client.rating}
                  starRatedColor="#F17425"
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  className="list-unstyled mb-0 mt-3"
                  starSpacing="1px"
                />
                <h6 className="text-light title-dark mt-1">
                  {client.name}
                  <small className="text-muted ms-1">{client.post}</small>
                </h6>
                <img
                  src={client.image}
                  style={{ height: 65, width: 65 }}
                  className="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                  alt=""
                />
              </div>
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" onClickHandler={this.previous}>
            <span className="carousel-control-prev-icon"></span>
            <span className="sr-only">Previous</span>
          </CarouselControl>
          <CarouselControl direction="next" onClickHandler={this.next}>
            <span className="carousel-control-next-icon"></span>
            <span className="sr-only">Next</span>
          </CarouselControl>
        </Carousel>
      </React.Fragment>
    );
  }
}

export default ReviewSlider4;
