// React Basic and Bootstrap
import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
// import { Gallery } from 'react-grid-gallery';
// import { Link } from 'react-router-dom';

//Import Icons
// import FeatherIcon from 'feather-icons-react';

//Import Components
import SectionTitle from '../../components/Shared/SectionTitle';
//import { hotelRoomsData } from "../../common/data";

// Import Images
import Img1 from '../../assets/images/Aquila Academy/Gallery/IMG-14.jpg';
import Img3 from '../../assets/images/Aquila Academy/Gallery/IMG-15.jpg';
import Img4 from '../../assets/images/Aquila Academy/Gallery/IMG-16.jpg';
import Img6 from '../../assets/images/Aquila Academy/Gallery/IMG-24.jpg';
import Img7 from '../../assets/images/Aquila Academy/Gallery/IMG-25.jpg';
import Img8 from '../../assets/images/Aquila Academy/Gallery/IMG-26.jpg';
import Img11 from '../../assets/images/Aquila Academy/Gallery/IMG-30.jpg';
import Img12 from '../../assets/images/Aquila Academy/Gallery/IMG-31.jpg';
import Img13 from '../../assets/images/Aquila Academy/Gallery/IMG-32.jpg';
import Img15 from '../../assets/images/Todzee/GalleryImages/IMG-41.jpg';
import Img16 from '../../assets/images/Todzee/GalleryImages/IMG-42.jpg';
import Img17 from '../../assets/images/Todzee/GalleryImages/IMG-43.jpg';
import Img18 from '../../assets/images/Todzee/GalleryImages/IMG-69.jpg';
import Img19 from '../../assets/images/Todzee/GalleryImages/IMG-45.jpg';
import Img21 from '../../assets/images/Todzee/GalleryImages/IMG-47.jpg';
import Img22 from '../../assets/images/Todzee/GalleryImages/IMG-48.jpg';
import Img23 from '../../assets/images/Todzee/GalleryImages/IMG-49.jpg';
import Img25 from '../../assets/images/Todzee/GalleryImages/IMG-50.jpg';
import Img26 from '../../assets/images/Todzee/GalleryImages/IMG-51.jpg';
import Img27 from '../../assets/images/Todzee/GalleryImages/IMG-52.jpg';
import Img28 from '../../assets/images/Todzee/GalleryImages/IMG-53.jpg';
import Img29 from '../../assets/images/Todzee/GalleryImages/IMG-54.jpg';
import Img30 from '../../assets/images/Todzee/GalleryImages/IMG-66.jpg';
import Img31 from '../../assets/images/Todzee/GalleryImages/IMG-55.jpg';
import Img32 from '../../assets/images/Todzee/GalleryImages/IMG-1.jpg';
import Img33 from '../../assets/images/Todzee/GalleryImages/IMG-2.jpg';
import Img34 from '../../assets/images/Todzee/GalleryImages/IMG-3.jpg';
import Img35 from '../../assets/images/Todzee/GalleryImages/IMG-4.jpg';
import Img36 from '../../assets/images/Todzee/GalleryImages/IMG-5.jpg';
import Img37 from '../../assets/images/Todzee/GalleryImages/IMG-6.jpg';
import Img38 from '../../assets/images/Todzee/GalleryImages/IMG-7.jpg';
import Img39 from '../../assets/images/Todzee/GalleryImages/IMG-8.jpg';
import Img40 from '../../assets/images/Todzee/GalleryImages/IMG-9.jpg';
import Img41 from '../../assets/images/Todzee/GalleryImages/IMG-10.jpg';
import Img42 from '../../assets/images/Todzee/GalleryImages/IMG-11.jpg';
import Img43 from '../../assets/images/Todzee/GalleryImages/IMG-12.jpg';
import Img44 from '../../assets/images/Todzee/GalleryImages/IMG-13.jpg';
import Img45 from '../../assets/images/Todzee/GalleryImages/IMG-14.jpg';
import Img46 from '../../assets/images/Todzee/GalleryImages/IMG-15.jpg';
import Img47 from '../../assets/images/Todzee/GalleryImages/IMG-16.jpg';
import Img48 from '../../assets/images/Todzee/GalleryImages/IMG-17.jpg';
import Img49 from '../../assets/images/Todzee/GalleryImages/IMG-18.jpg';
import Img50 from '../../assets/images/Todzee/GalleryImages/IMG-19.jpg';
import Img51 from '../../assets/images/Todzee/GalleryImages/IMG-20.jpg';
import Img52 from '../../assets/images/Todzee/GalleryImages/IMG-21.jpg';

const Images = [Img1, Img3, Img4, Img6, Img7, Img8, Img11, Img12, Img13];

const Images1 = [
  ...Images,
  Img11,
  Img13,
  Img15,
  Img16,
  Img28,
  Img30,
  Img19,
  Img21,
  Img18,
  Img12,
  Img31,
  Img22,
  Img23,
  Img25,
  Img26,
  Img27,
  Img17,
  Img29,
];

const Images2 = [
  ...Images1,
  Img32,
  Img51,
  Img34,
  Img35,
  Img36,
  Img37,
  Img38,
  Img39,
  Img40,
  Img41,
  Img42,
  Img43,
  Img45,
  Img46,
  Img47,
  Img48,
  Img49,
  Img50,
  Img52,
  Img33,
  Img44,
];

const Gallery = () => {
  const [readMore, setReadMore] = useState(0);

  const handleGetImagesfromList = () => {
    if (readMore === 0) {
      return setReadMore(1);
    } else if (readMore === 1) {
      return setReadMore(2);
    } else if (readMore === 2) {
      toTop();

      return setReadMore(0);
    }
  };

  const getImages = () => {
    if (readMore === 0) {
      return Images;
    } else if (readMore === 1) {
      return Images1;
    } else if (readMore === 2) {
      return Images2;
    }
  };

  const toTop = () => {
    document.body.scrollTop = 2500;
    document.documentElement.scrollTop = 2500;
  };

  window.onscroll = function () {
    backToTopButton();
  };

  function backToTopButton() {
    var ele = document.getElementById('back-to-top');
    if (ele) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        ele.style.display = 'block';
      } else {
        ele.style.display = 'none';
      }
    }
  }

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* section title */}
          <SectionTitle title="Gallery" />

          <Row>
            {getImages().map((img, key) => (
              <Col lg="4" md="6" key={key} className="mt-4 pt-2">
                <Card className="work-container work-modern rounded border-0 overflow-hidden">
                  <CardBody className="p-0">
                    <img src={img} className="img-fluid rounded" alt="work" />
                    <div className="overlay-work"></div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="justify-content-center">
            <Col xs="12" className="text-center mt-4 pt-2">
              {/* <button
                className="btn btn-primary btn-sm"
                onClick={handleGetImagesfromList}
              >
                {readMore === 2 ? 'View Less' : 'View More'}
              </button> */}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Gallery;
