// React Basic and Bootstrap
import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  FormFeedback,
  Link,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

const Contact = () => {
  const schoolId = 'xEe4VUAaiX';
  const enquiryFor = ['Daycare', 'Playgroup', 'Nursery', 'LKG', 'UKG'];
  const parents = ['Father', 'Mother'];
  const [onSave, setOnSave] = useState(false);
  const [error, setError] = useState(undefined);
  const [responce, setResponce] = useState(undefined);
  const [enquiryForError, setEnquiryForError] = useState(undefined);
  const [relationError, setRelationError] = useState(undefined);
  const [succeMsg, setSucceMsg] = useState(false);

  const saveEnquiryTolilTriangle = (enqObj) => {
    fetch(
      // "https://development.back4app.io/addEnquiry", //development
      'https://liltriangle.b4a.app/addEnquiry', //production
      {
        method: 'POST',
        body: JSON.stringify({
          schoolId: schoolId,
          childName: enqObj.childName,
          relation: enqObj.relation,
          enquiryFor: enqObj.enquiryFor,
          enquirySource: 'Website',
          parentName: enqObj.parentName,
          phone: enqObj.phone,
          email: enqObj.email,
          notes: enqObj.notes,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    ).then(
      (response) => {
        console.log(response);
        setOnSave(false);
        if (response.status === 200) {
          setResponce('SUCCESS');
          setError(undefined);
          setTimeout(() => {
            setResponce(undefined);
          }, 2000);
          clearValues();
        } else {
          response.json().then((data) => {
            console.log(data);
            setError(data.error);
            setResponce(undefined);
          });
        }
      },
      (error) => {
        console.log('error' + error.message);
      },
    );
  };
  const clearValues = () => {
    document.getElementById('dzChildName').value = '';
    document.getElementById('dzRelation').value = true;
    document.getElementById('dzEnquiryFor').value = true;
    document.getElementById('dzName').value = '';
    document.getElementById('dzPhone').value = '';
    document.getElementById('dzEmail').value = '';
    document.getElementById('dzAdditionalNote').value = '';
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Name'),
      email: Yup.string().required('Email is required'),
    }),
    onSubmit: (values) => {
      // console.log(values)
      setSucceMsg(true);
    },
  });

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            <Col lg="8" md="6" className="p-0 ps-md-3 pe-md-3">
              {/* <Container className="mt-100 mt-60"> */}
              <Row className="mb-2">
                <Col lg={6} md={6} xs={12}>
                  <div className="d-flex align-items-center features feature-clean shadow rounded p-2">
                    <div className="icons text-primary text-center">
                      <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                    </div>
                    <div className="flex-1 content ms-4">
                      <h5 className="mb-1">+91 7483515508</h5>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <div className="d-flex align-items-center features feature-clean shadow rounded p-2">
                    <div className="icons text-primary text-center">
                      <i className="uil uil-envelope-check d-block rounded h3 mb-0"></i>
                    </div>
                    <div className="flex-1 content ms-4">
                      <h5 className="mb-1">
                        {/* <Link to="#" className="text-dark">
                            Get in Touch !
                          </Link> */}
                        <a
                          href="mailto:info@aquilaacademy.co.in"
                          style={{ color: 'black' }}
                        >
                          info@aquilaacademy.co.in
                        </a>
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* </Container> */}

              <div className="map map-height-two rounded map-gray border-0">
                <iframe
                  title="myFrame"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.041328468827!2d77.73334287507637!3d12.969207287345945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13e4893e930f%3A0xc1550bac8aef25f1!2sAquila%20Academy%20Preschool%20And%20Daycare%20Whitefield%2C%20Nallurhalli%2C%20Bengaluru%20%7C%20Best%20Preschool%20In%20Nallurahalli!5e0!3m2!1sen!2sin!4v1715690603129!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  className="rounded"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>

            <Col lg="4" md="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <Card className="rounded shadow border-0">
                <CardBody className="py-2">
                  <h5 className="card-title" style={{ textAlign: 'center' }}>
                    {' '}
                    <strong> Enquire Us</strong>{' '}
                  </h5>
                  <p style={{ textAlign: 'center' }}>
                    Our team will get in touch with you !
                  </p>
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                      // console.log(event.target.elements.dzChildName.value);
                      // console.log(event.target.elements.dzEnquiryFor.value);
                      // console.log(event.target.elements.dzName.value);
                      // console.log(event.target.elements.dzPhone.value);
                      // console.log(event.target.elements.dzEmail.value);
                      // console.log(event.target.elements.dzAdditionalNote.value);
                      // console.log(event.target.elements.dzRelation.value);
                      if (relationError) {
                        setError(false);
                      }
                      if (
                        !enquiryFor.includes(
                          event.target.elements.dzEnquiryFor.value,
                        )
                      ) {
                        setEnquiryForError(true);
                        if (
                          !parents.includes(
                            event.target.elements.dzRelation.value,
                          )
                        ) {
                          setRelationError(true);
                        }
                      } else if (
                        !parents.includes(
                          event.target.elements.dzRelation.value,
                        )
                      ) {
                        setRelationError(true);
                      } else {
                        setOnSave(true);
                        let enqObj = {
                          childName: event.target.elements.dzChildName.value,
                          relation: event.target.elements.dzRelation.value,
                          enquiryFor: event.target.elements.dzEnquiryFor.value,
                          parentName: event.target.elements.dzName.value,
                          phone: event.target.elements.dzPhone.value,
                          email: event.target.elements.dzEmail.value,
                          notes: event.target.elements.dzAdditionalNote.value,
                        };
                        saveEnquiryTolilTriangle(enqObj);
                      }
                    }}
                    className="dzForm col-md-12"
                  >
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Child Name</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons text-success"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="location1"
                            type="text"
                            required
                            className="form-control ps-5"
                            placeholder="Enter your Child name"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Enquiry For</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="clipboard"
                                className="fea icon-sm icons text-danger"
                              />
                            </i>
                            <select
                              className="form-control ps-5"
                              required
                              // style={{ width: "100%", height: "60px", backgroundColor: "#c4eafb", border: "0" }}
                              name="dzEnquiryFor"
                              id="dzEnquiryFor"
                              onChange={(e) => {
                                if (enquiryForError) {
                                  setEnquiryForError(false);
                                }
                              }}
                            >
                              <option disabled selected value>
                                -- enquiring for --
                              </option>
                              {enquiryFor.map((e, k) => (
                                <option key={k} value={e}>
                                  {e}
                                </option>
                              ))}
                            </select>
                            {enquiryForError && (
                              <p className="text-danger">{'Please select '}</p>
                            )}
                          </div>
                          {/* <Input
                            name="name"
                            id="location2"
                            type="text"
                            className="form-control ps-5"
                            placeholder="-- enquiring for --"
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Parent Details</Label>
                          <div className="form-group ">
                            <select
                              className="form-control"
                              required
                              // style={{ width: "100%", height: "60px", backgroundColor: "#c4eafb", border: "0" }}
                              name="dzRelation"
                              id="dzRelation"
                              onChange={(e) => {
                                if (relationError) {
                                  setRelationError(false);
                                }
                              }}
                            >
                              <option disabled selected value>
                                -- select relation --
                              </option>
                              {parents.map((e, k) => (
                                <option key={k} value={e}>
                                  {e}
                                </option>
                              ))}
                            </select>
                            {relationError && (
                              <p className="text-danger">{'Please select '}</p>
                            )}
                          </div>
                          {/* <div className="form-icon position-relative">
                            
                          </div>
                          <Input
                            name="name"
                            id="location1"
                            type="text"
                            className="form-control ps-5"
                            placeholder="-- select relation --"
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Name</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons text-success"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="location1"
                            type="text"
                            required
                            className="form-control ps-5"
                            placeholder="Name"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Phone Number</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="phone"
                                className="fea icon-sm icons text-success"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="location1"
                            type="text"
                            required
                            className="form-control ps-5"
                            placeholder="Phone"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Email</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons text-success"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="location1"
                            type="text"
                            required
                            className="form-control ps-5"
                            placeholder="Email Address"
                          />
                        </FormGroup>
                      </Col>

                      <div className="text-center col-md-12">
                        {onSave && <p>SAVING...........</p>}
                        {!onSave && error && (
                          <p className="text-danger">{error}</p>
                        )}
                        {!onSave && responce && (
                          <p className="text-success">{responce}</p>
                        )}
                      </div>

                      <div className="col-md-12 col-sm-12 text-center">
                        <button
                          name="submit"
                          type="submit"
                          disabled={onSave}
                          value="Submit"
                          className="btn btn-primary mdi mdi-chevron-right"
                        >
                          Submit<span></span>
                        </button>
                      </div>
                    </Row>
                    {/* <Link to="" className="btn btn-primary">
                      Submit <i className="mdi mdi-chevron-right"></i>
                    </Link> */}
                  </Form>

                  {/* <div className="custom-form mt-4">
                    <div id="message"></div>
                    <Alert
                      color="primary"
                      isOpen={succeMsg}
                      toggle={() => {
                        setSucceMsg(false);
                      }}
                    >
                      Contact details send successfully.
                    </Alert>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        if (relationError) {
                          setError(false);
                        }
                        if (
                          !enquiryFor.includes(
                            event.target.elements.dzEnquiryFor.value,
                          )
                        ) {
                          setEnquiryForError(true);
                          if (
                            !parents.includes(
                              event.target.elements.dzRelation.value,
                            )
                          ) {
                            setRelationError(true);
                          }
                        } else if (
                          !parents.includes(
                            event.target.elements.dzRelation.value,
                          )
                        ) {
                          setRelationError(true);
                        } else {
                          setOnSave(true);
                          let enqObj = {
                            childName: event.target.elements.dzChildName.value,
                            relation: event.target.elements.dzRelation.value,
                            enquiryFor:
                              event.target.elements.dzEnquiryFor.value,
                            parentName: event.target.elements.dzName.value,
                            phone: event.target.elements.dzPhone.value,
                            email: event.target.elements.dzEmail.value,
                            notes: event.target.elements.dzAdditionalNote.value,
                          };
                          saveEnquiryTolilTriangle(enqObj);
                        }
                        // return false;
                      }}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Name <span className="text-danger">*</span>{' '}
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="user"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control ps-5"
                              placeholder="First Name :"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ''}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Email <span className="text-danger">*</span>{' '}
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control ps-5"
                              placeholder="Your email :"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">Comments </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="message-circle"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              className="form-control ps-5"
                              placeholder="Your Message :"
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Send Message"
                            />
                            <div id="simple-msg"></div>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
