import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Link } from 'reactstrap';
import bg03 from '../../assets/images/Todzee/life Skills.jpg';
import bg04 from '../../assets/images/Aquila Academy/CurrDesigner.jpeg';

const GreatFeatures = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <Container className="mt-80 mt-60 mb-4">
        <Row className="">
          <Col lg={{ size: 5, order: 1 }} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
            <div className=" sticky-bar">
              <h1 className="mb-3">Aquila Academy Learning Pedagogy</h1>
              <h5 className="text-muted">
                At Aquila Academy, we embrace the National Education Policy
                (NEP) 2020 to foster a holistic, enjoyable, and multi-faceted
                education for our preschoolers. Our curriculum emphasizes
                play-based and activity-based learning, nurturing curiosity,
                creativity, and cognitive skills. We focus on foundational
                literacy and numeracy, ensuring that every child develops
                essential life skills through engaging and interactive methods.
              </h5>

              <div className="">
                <div
                  className="mt-4 title"
                  style={{
                    backgroundColor: 'rgba(47, 85, 212, 0.1)',
                    padding: '40px',
                    fontSize: '21px',
                  }}
                >
                  <strong>
                    Our teachers aim at nurturing students with utmost care and
                    ensure the following each and every step
                  </strong>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
            <div>
              {isMobileView ? (
                <img
                  src={bg03}
                  alt="image"
                  style={{ height: '380px', width: '380px' }}
                />
              ) : (
                <img src={bg03} className="" alt="image" />
              )}
            </div>
          </Col>
        </Row>

        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={6} md={6}>
              <img src={bg04} className="img-fluid" alt="" />
            </Col>

            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-3">
                <h4 className="title mb-4">Kreedo Partnership</h4>
                <h5 className="text-muted">
                  We are proud to partner with Kreedo, a leading curriculum
                  provider. Kreedo's approach aligns seamlessly with NEP
                  guidelines, offering structured, age-appropriate learning
                  modules. Their innovative educational tools and resources
                  enhance our teaching methods, creating a dynamic and enriching
                  environment for our young learners. Through this
                  collaboration, we ensure that our students receive the best
                  early childhood education, setting a strong foundation for
                  their future academic journey.
                </h5>
                {/* <Link to="#" className="mt-3 h6 text-primary">
                  Find Out More <i className="uil uil-angle-right-b align-middle"></i>
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>

        <Row>
          <h5 className="text mt-4">
            <h2 className="mb-3  mt-2 text-dark">Engaging Activities</h2>
            Our curriculum boasts a diverse range of activities meticulously{' '}
            designed to support every facet of a child's development: <p></p>•{' '}
            <strong>Materials-based Learning:</strong> Hands-on exploration with
            various materials to stimulate creativity and critical thinking.{' '}
            <br></br>•<strong> Group Art, Language, & Motor Skills: </strong>
            Collaborative activities to enhance artistic expression, language
            development, and fine motor skills. <br></br>•{' '}
            <strong>Role-plays & Presentations:</strong> Opportunities for
            imaginative play and public speaking to boost confidence and
            communication skills. <br></br>• <strong>Excursions:</strong> Field
            trips and outdoor experiences to enrich learning and foster a deeper
            connection with the world around us.
            <br></br> • <strong>Cultural Festivities:</strong> Celebrations of
            diversity and cultural traditions, promoting respect and
            understanding among peers. <br></br>•{' '}
            <strong>STEM Education:</strong> Introduction to Science,
            Technology, Engineering, and Mathematics concepts through
            interactive and experiential learning. <br></br> •{' '}
            <strong>Independent Exploration:</strong> Encouragement for
            self-directed learning and discovery, empowering children to follow
            their interests. <br></br> •{' '}
            <strong>Life Skills Integration:</strong> Practical activities aimed
            at developing essential life skills such as problem-solving,
            decision-making, and self-care.
          </h5>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default GreatFeatures;
