import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Label } from 'reactstrap';
import { Link } from 'react-router-dom';

// import images
import blog1 from '../../assets/images/blog/01.jpg';
import Img1 from '../../assets/images/Aquila Academy/Gallery/IMG-9.jpg';
import Img2 from '../../assets/images/Todzee/GalleryImages/IMG-57.jpg';
import Img3 from '../../assets/images/Aquila Academy/Gallery/IMG-6.jpg';
import Img4 from '../../assets/images/Aquila Academy/Gallery/IMG-3.jpg';
import Img5 from '../../assets/images/Aquila Academy/Gallery/IMG-1.jpg';
import Img6 from '../../assets/images/Aquila Academy/Gallery/IMG-4.jpg';
import { object } from 'yup';
//import { useState } from 'react/cjs/react.production.min';

const Cards = () => {
  const [business, setbusiness] = useState([
    {
      id: 0,
      image: Img1,
      title: '(1.2 to 4.7 years) - Daycare',
      isOpen: false,
      description:
        'Social, Emotional, Language and Physical development is the prior focus.',
      description1:
        'Social, Emotional, Language and Physical development is the prior focus. As the child starts to get inquisitive about the visual content in his/her surrounding, excited about the sounds heard, and when the development of his/her kinetic energy starts to build stronger, it is important for us to trigger and help develop the basic personality of a child. While still developing the concept of sharing, children in this age group begin to understand the basics of taking turns and sharing toys with peers and exploring their environment.',
    },
    {
      id: 1,
      image: Img2,
      title: '(2 to 3 years) - PlayGroup',
      isOpen: false,
      description:
        'Motor, Cognitive, Language and Numeracy development is the prior',
      description1:
        'Motor, Cognitive, Language and Numeracy development is the prior focus. As the child gradually has developed on the SELP aspect of understanding colors, visual presence of mind and emotional expressions, the energy then for the child is to kickstart his motor skills to help reach to whatever he is attracted. Then on the cognitive skills help the child to think, understand and memorize what he sees, reads or listens. By now the child starts to master many languages he is taught with. To top it all the child starts to develop numeracy skills.',
    },
    {
      id: 2,
      image: Img3,
      title: '(3 to 4 years) - Nursery',
      isOpen: false,
      description:
        'As the child is guided with the right developmental modules in mastering his',
      description1:
        "As the child is guided with the right developmental modules in mastering his social, emotional, language, physical, motor, cognitive and numeracy skills, Literacy and communication becomes the icing on the cake for a right personality to be molded. Literacy will help the child develop into a sensible, responsible and academically sound individual while communication helps the parent, teacher's and his clan to understand the kind of human being he/she is and the thought process which the child values.",
    },
    {
      id: 3,
      image: Img4,
      title: '(4 to 5 years) - LKG',
      isOpen: false,
      description:
        'LKG class offers young children an engaging introduction to formal edu..',
      description1:
        'LKG class offers young children an engaging introduction to formal education. It focuses on developing basic literacy, numeracy, and social skills through playful and interactive activities. With a balanced mix of learning and play, LKG helps children build a strong foundation for their future academic journey.',
    },
    {
      id: 4,
      image: Img5,
      title: '(5 to 6 years) - UKG',
      isOpen: false,
      description:
        'UKG class prepares children for primary school with a focus on advanced.',
      description1:
        'UKG class prepares children for primary school with a focus on advanced literacy and numeracy skills. It promotes critical thinking, creativity, and social interaction through structured lessons and play-based activities. UKG nurtures confidence and curiosity, ensuring children are ready for the next step in their educational journey.',
    },
    {
      id: 5,
      image: Img6,
      title: '(2.7 ++) - Summer Camp',
      isOpen: false,
      description:
        'Offers a fun and enriching experience for young children during the summer.',
      description1:
        'Offers a fun and enriching experience for young children during the summer break. It combines educational activities, outdoor play, arts and crafts, and themed adventures. The camp fosters creativity, social skills, and physical development in a safe, supervised environment, making summer both enjoyable and productive for kids.',
    },
  ]);
  return (
    <React.Fragment>
      <section className="section" id="ui-card">
        <Container>
          <Row>
            <Col lg={12} md={8} className="col-12">
              <Row>
                <div className="text-center">
                  <h2>Why Aquila Academy?</h2>
                  <p>
                    Children at every stage of their growth require different
                    developmental needs and it is every parents and teachers
                    responsibility to cater to the right developmental need and
                    approach at the right time.
                  </p>
                </div>
                <Col>
                  <div className="component-wrapper rounded shadow">
                    <div className="p-4">
                      <Row>
                        {business.map((data, id) => (
                          <Col lg={4} className="col-12 mt-4 pt-2" key={id}>
                            <Card className="shadow rounded border-0 overflow-hidden">
                              <img
                                src={data.image}
                                className="img-fluid"
                                alt=""
                              />
                              <CardBody>
                                <h5 className="card-title">{data.title}</h5>

                                {data.isOpen == true
                                  ? data.description1
                                  : data.description}

                                {data.isOpen ? (
                                  <Label
                                    className="btn text-primary"
                                    onClick={() => {
                                      let l = [...business];
                                      l[data.id].isOpen = false;
                                      setbusiness(l);
                                    }}
                                  >
                                    Read Less
                                  </Label>
                                ) : (
                                  <Label
                                    className="btn text-primary"
                                    onClick={() => {
                                      let l = [...business];
                                      l[data.id].isOpen = true;
                                      setbusiness(l);
                                    }}
                                  >
                                    Read More
                                  </Label>
                                )}
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Cards;
